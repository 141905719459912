import Axios from "axios"
import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import baseUrl from "../../components/baseUrl"
import Confirm from "../../components/confirm"
import Layout from "../../components/layout"
import "../../styles/logPage.css"
// import { navigate } from "gatsby"

function Logs() {
  const [report, setReport] = useState("")
  const [showModal, setShowModal] = useState(false)
  const isBrowser = typeof window !== "undefined"
  let username = ""
  let name = ""
  //Converting date object to proper string
  let dateObj = new Date()
  var month = dateObj.getUTCMonth() + 1 //months from 1-12
  var day = dateObj.getUTCDate()
  var year = dateObj.getUTCFullYear()
  let newDate = day + "/" + month + "/" + year
  if (isBrowser) {
    username = window.localStorage.getItem("username")
    name = window.localStorage.getItem("user")
  }
  const handleSubmit = () => {
    Axios.post(`${baseUrl}/dailylogs`, {
      name: name,
      username: username,
      date: newDate,
      report: report,
    })
      .then(() => {
        alert("Submission successful")
        navigate("/logbook")
      })
      .catch(err => console.log(err))
    setShowModal(false)
  }

  return (
    <Layout route="/logbook">
      <div className="logPage">
        <h2 className="p-6">SUBMIT REPORT</h2>
        <div className="formRow p-6 pb-0 md:p-6 md:pb-6 ">
          <form class="w-full">
            <div class="flex flex-wrap -mx-3  mb-6">
              <div class="w-full  px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
                  WRITE YOUR REPORT HERE
                </label>
                <textarea
                  class="appearance-none block w-full h-[50vh] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-zip"
                  onChange={e => {
                    setReport(e.target.value)
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="flex items-center justify-start p-6 md:p-6  border-t border-solid border-blueGray-200 rounded-b">
          <button
            className="bg-emerald-500 text-white active:bg-emerald-600 font-semibold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              if (report == "") {
                alert("Cannot submit empty report!")
              } else setShowModal(true)
            }}
          >
            SUBMIT REPORT
          </button>
          <Link to="/logbook">
            <button
              className="bg-red-500 text-white active:bg-red-600 font-semibold ml-6 uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              BACK
            </button>
          </Link>
        </div>
      </div>
      {showModal ? (
        <Confirm
          title="Submit report?"
          task={handleSubmit}
          setShowModal={setShowModal}
        />
      ) : null}
    </Layout>
  )
}

export default Logs
